//開発環境
// export const HomeUrl = 'http://localhost:3000/mercado_anken/shoshin/react/'
// export const HomeDir = '/mercado_anken/shoshin'
// export const ApiDir = '/mercado_anken/shoshin/api'
// export const JsDir = '/mercado_anken/shoshin/js'
// export const ImagesDir = '/mercado_anken/shoshin/images'
// export const RouteDir = '/mercado_anken/shoshin/react'
// export const PublicImageDir = '/mercado_anken/shoshin/react/images'
// export const AdminsDir = '/mercado_anken/shoshin/react/admin'
// export const MypageDir = '/mercado_anken/shoshin/react/mypage'
// export const NewsImageDir = '/mercado_anken/shoshin/images/news'
// export const ItemImageDir = '/mercado_anken/shoshin/images/items'

//本番環境
export const HomeUrl = 'https://shoshinsp-ec.com/'
export const RouteDir = ''
export const HomeDir = ''
export const ApiDir = '/api'
export const JsDir = '/js'
export const SvgDir = '/svg'
export const FontsDir = '/fonts'
export const ImagesDir = '/images'
export const PublicImageDir = '/images'
export const AdminsDir = '/admin'
export const MypageDir = '/mypage'
export const NewsImageDir = '/images/news'
export const ItemImageDir = '/images/items'


//ステージング環境
// export const HomeUrl = 'http://153.127.27.209/seihi_ec_test/'
// export const RouteDir = '/seihi_ec_test'
// export const HomeDir = '/seihi_ec_test'
// export const ApiDir = '/seihi_ec_test/api'
// export const JsDir = '/seihi_ec_test/js'
// export const SvgDir = '/seihi_ec_test/svg'
// export const FontsDir = '/seihi_ec_test/fonts'
// export const ImagesDir = '/seihi_ec_test/images'
// export const PublicImageDir = '/seihi_ec_test/images'
// export const AdminsDir = '/seihi_ec_test/admin'
// export const MypageDir = '/seihi_ec_test/mypage'
// export const NewsImageDir = '/seihi_ec_test/images/news'
// export const ItemImageDir = '/seihi_ec_test/images/items'


//オンライン決済
//テスト決済環境
export const applicationId = 'sandbox-sq0idb-HYRCtQzPcVMk_P4urvjA2A'
export const locationId = 'LJNQ5WBEE7C0Y'
export const copyRight = '*******'


//エラー回避用古い情報
export const postUrl = 'https://sandbox.paygent.co.jp/v/u/request'
export const merchantId = '49225'
export const merchantName = 'ＪＡ西彼'

// 221121 決済ページ最新バージョンに置き換え応急処置　ここから
// Squareテスト決済環境
export const sqAppId = 'sandbox-sq0idb-TX7Er3KW4cxyfAUBzxznIw'; /* 変更必要 */
export const sqLocationId = 'L9PS7Y0F82N8F'; /* 変更必要 */

// 3Dセキュア　変更必要
export const isThreeDSecureActivated = false; /* 無効 */
// export const isThreeDSecureActivated = true /* 有効 */

// 221121 決済ページ最新バージョンに置き換え応急処置　ここまで